import { graphql, useStaticQuery } from 'gatsby'
import { VideoTabbar } from './type'

interface FAQItem {
  faq_h: string
  faq_q: string
  faq_a: string
}

interface EventPricing {
  h: string
  q: string
  before: string
  after: string
  prices: {
    item: {
      label: string
      price: string
      price_offer?: string
    }
  }[]
  offer_active: boolean
  // offer_img?: FixedObject
}

interface CoronaBubble {
  h: string
  content: string
}

interface EventB2C {
  title: string
  intro: string
  corona_bubble: CoronaBubble
  videos: VideoTabbar
  impression: string
  faq: {
    faq_item: FAQItem
  }[]
  pricing: EventPricing
  outro: string
}

interface TData {
  eventB2CYaml: { [lang: string]: EventB2C }
}

export const eventB2CQuery = graphql`
  query EventB2CQuery {
    eventB2CYaml {
      de {
        title
        intro
        corona_bubble {
          h
          content
        }
        videos {
          tab_1 {
            title
            videoId
          }
          tab_2 {
            title
            videoId
          }
        }
        impression
        faq {
          faq_item {
            faq_h
            faq_q
            faq_a
          }
        }
        pricing {
          h
          q
          before
          after
          prices {
            item {
              label
              price
              price_offer
            }
          }
          offer_active
        }
        outro
      }
    }
  }
`

export const useEventB2C = () => useStaticQuery<TData>(eventB2CQuery).eventB2CYaml.de
